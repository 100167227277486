import React, { useContext, useEffect, useState } from 'react';
import { ApiDataContext } from '../../../context/ApiDataContext';
import { Skeleton } from 'primereact/skeleton';
import { Link } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import './ProductOverview.css';

const ProductOverview = () => {

    const { productPageData, theme } = useContext(ApiDataContext);
    const [loading, setLoading] = useState(true);
    const [hoveredItem, setHoveredItem] = useState(null);

    useEffect(() => {
        if (productPageData && productPageData.length > 0) {
            setLoading(false);
            setHoveredItem({
                projectTitle: productPageData[0]?.projectTitle,
                description: productPageData[0]?.productKnowDescription,
                buttonLink: productPageData[0]?.download,
                backgroundImage: productPageData[0]?.productBackImage,
                helpCenter: productPageData[0]?.helpCenter
            })
        }
    }, [productPageData]);

    const handleMouseEnter = (item) => {
        setHoveredItem({
            projectTitle: item.projectTitle,
            description: item.productKnowDescription,
            buttonLink: item.download,
            backgroundImage: item.productBackImage,
            helpCenter: item.helpCenter
        });
    };

    function getLogoForProduct(productName) {

        switch (productName) {
            case 'Learning Management Portal':
                return theme === 'light' ? require('../../../assets/images/icons/productIcons/lmp-w.png') : require('../../../assets/images/icons/productIcons/lmp-b.png');
            case 'Asset Management System':
                return theme === 'light' ? require('../../../assets/images/icons/productIcons/ams-w.png') : require('../../../assets/images/icons/productIcons/ams-b.png');
            case 'Audit Inspection':
                return theme === 'light' ? require('../../../assets/images/icons/productIcons/audit-w.png') : require('../../../assets/images/icons/productIcons/audit-b.png');
            case 'Construction Management System':
                return theme === 'light' ? require('../../../assets/images/icons/productIcons/cms-w.png') : require('../../../assets/images/icons/productIcons/cms-b.png');
            case 'SPE Intranet':
                return theme === 'light' ? require('../../../assets/images/icons/productIcons/spe-Intranet-w.png') : require('../../../assets/images/icons/productIcons/spe-Intranet-b.png');
            case 'HelpDesk System':
                return theme === 'light' ? require('../../../assets/images/icons/productIcons/hd-w.png') : require('../../../assets/images/icons/productIcons/hd-b.png');
            case 'Inventory Management System':
                return theme === 'light' ? require('../../../assets/images/icons/productIcons/ims-w.png') : require('../../../assets/images/icons/productIcons/ims-b.png');
            case 'Approval System':
                return theme === 'light' ? require('../../../assets/images/icons/productIcons/as-w.png') : require('../../../assets/images/icons/productIcons/as-b.png');

            default:
                return null;
        }
    };

    function getcardLogoForProduct(productName) {

        switch (productName) {
            case 'Learning Management Portal':
                return require('../../../assets/images/icons/productIcons/lmp-w.png');
            case 'Asset Management System':
                return require('../../../assets/images/icons/productIcons/ams-w.png');
            case 'Audit Inspection':
                return require('../../../assets/images/icons/productIcons/audit-w.png');
            case 'Construction Management System':
                return require('../../../assets/images/icons/productIcons/cms-w.png');
            case 'SPE Intranet':
                return require('../../../assets/images/icons/productIcons/spe-Intranet-w.png');
            case 'HelpDesk System':
                return require('../../../assets/images/icons/productIcons/hd-w.png');
            case 'Inventory Management System':
                return require('../../../assets/images/icons/productIcons/ims-w.png');
            case 'Approval System':
                return require('../../../assets/images/icons/productIcons/as-w.png');

            default:
                return null;
        }
    };

    return (
        <>
            <div className="container p-4 my-3 product-page-background">
                <div className='row'>
                    <div className='col col-12 col-sm-12 col-md-6 col-lg-5 col-xl-3 col-xxl-3'>
                        <div>
                            <ul className="system-list">
                                {loading ? (
                                    <>
                                        <Skeleton width="100%" height="70px" className='my-2' />
                                        <Skeleton width="100%" height="70px" className='my-2' />
                                        <Skeleton width="100%" height="70px" className='my-2' />
                                        <Skeleton width="100%" height="70px" className='my-2' />
                                        <Skeleton width="100%" height="70px" className='my-2' />
                                        <Skeleton width="100%" height="70px" className='my-2' />
                                        <Skeleton width="100%" height="70px" className='my-2' />
                                        <Skeleton width="100%" height="70px" className='my-2' />
                                    </>
                                ) : (
                                    productPageData?.map((item, index) => {
                                        let updatedTitle = item?.projectTitle

                                        if (item.projectTitle?.includes('Management') && (item.projectTitle?.includes('System') || item.projectTitle?.includes('Portal'))) {
                                            updatedTitle = item.projectTitle.replace('System', '').replace('Portal', '').trim();
                                        }

                                        return (
                                            <li className='zoom-on-hover' title={updatedTitle} key={index}>
                                                <div className='flex align-items-center' onMouseEnter={() => handleMouseEnter(item)}>
                                                    <Link to={item?.routPath} className='gap-2 '>
                                                        <img className="product-icons" src={getLogoForProduct(item.projectTitle)} alt={item.projectTitle} title="" />
                                                        <span>{updatedTitle}</span>
                                                        <img className="microsoft-badge" src={theme === 'light' ? require('../../../../src/assets/images/microsoftSolutionsPartner/microsoft-badge-white.png') : require('../../../../src/assets/images/microsoftSolutionsPartner/microsoft-badge-black.png')} alt={item.projectTitle} title="" />
                                                    </Link>
                                                </div>
                                                <Tooltip target=".zoom-on-hover" position="bottom" mouseTrack mouseTrackTop={-20} className='z-5' />
                                            </li>
                                        )
                                    })
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className='col col-12 col-sm-12 col-md-6 col-lg-7 col-xl-9 col-xxl-9 '>
                        <div className='h-full bg-cover flex align-items-end border-round-lg mobile-disable' style={{ backgroundImage: hoveredItem?.backgroundImage && `url(${require(`../../../assets/images/productsBackground/${hoveredItem?.backgroundImage}.webp`)})` }}>
                            <div className="main-content m-3 w-full text-white" >
                                {loading ? (
                                    <>
                                        <div className="flex gap-2">
                                            <Skeleton width="5%" height="40px" className="my-2" />
                                            <Skeleton width="50%" height="40px" className="my-2" />
                                        </div>
                                        <Skeleton width="100%" height="100px" className="my-2" />
                                        <div className="flex gap-2">
                                            <Skeleton width="120px" height="40px" className="my-2" />
                                            <Skeleton width="120px" height="40px" className="my-2" />
                                        </div>
                                    </>
                                ) : (
                                    hoveredItem && (
                                        <>
                                            <div className="product-title flex align-items-center gap-2">
                                                <img className="all-product-icon" src={getcardLogoForProduct(hoveredItem.projectTitle)} alt={hoveredItem.projectTitle} title="" />
                                                <h2 className='m-0'>{hoveredItem.projectTitle}</h2>
                                            </div>
                                            <p>{hoveredItem.description}</p>
                                            <div className="product-buttons">
                                                <a href={hoveredItem.buttonLink} target='_blank' rel="noopener noreferrer"><button className="download-walkthrough-button">Download</button></a>
                                                <Link to={hoveredItem.helpCenter} target='_blank'><button className="download-walkthrough-button">Product Walkthrough</button></Link>
                                            </div>
                                        </>
                                    )
                                )}
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        </>
    );
};

export default ProductOverview;
