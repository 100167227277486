import React, { useContext } from 'react';
import { ApiDataContext } from '../../../context/ApiDataContext';
import './ClientsAcrossTheGlobe.css';

const ClientsAcrossTheGlobe = () => {
    const { theme } = useContext(ApiDataContext);

    return (
        <div className="container-fluid">
            <div className='relative'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div>
                            <div id="first-section-margin-top-for-navbar">
                                <h1 className="h1-tag-lm h1-heading">Clients Across The Globe</h1>
                            </div>
                            <div>
                                <p className="p-content-lm">
                                    At <b>SharepointEmpower</b>, we are proud to serve clients across the globe with our innovative and high-quality services. With our global reach, we have the ability to work with clients from different time zones, cultures, and backgrounds. Our team is experienced in collaborating remotely through video conferencing, email, and other communication tools to ensure seamless communication with our clients.
                                </p>
                            </div>
                        </div>
                        <div className="flex justify-content-center relative">
                            <img className='md:w-7 w-full' src={theme === 'dark-mode-body' ? `${require('../../../assets/images/globalMap/map-dark-theme.png')}` : `${require('../../../assets/images/globalMap/map-light-theme.png')}`} alt="loading" title="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientsAcrossTheGlobe;
