import React, { useEffect } from 'react';
import ReactHelmetTag from '../../commonComponents/reactHelmet/ReactHelmetTag';
import { OUR_VALUE_DATA } from '../../../utils/data';
import Megamenu from '../../megamenu/Megamenu';
import PageHeadingContent from '../../commonComponents/pageHeadingContent/PageHeadingContent';
import './WhoWeAre.css';

const WhoWeAre = () => {

  useEffect(() => {
    document.title = "About us";
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Megamenu />
      <div className='mt-8'>
        <ReactHelmetTag
          link="https://www.sharepointempower.com/who-we-are"
          metaKeywords="about us, About sharepointempower, Who are we?, Sharepointempower about us"
        />
        <div classNameName="light-mode-service-subtract-bg"></div>
        <PageHeadingContent heading="Who we are?" subtitle="We are your partner in success" />

        <div className="about-us-banner-container">
          <div className="about-us-banner-image">
            <div className="about-us-banner-text-overlay">
              <p className="about-us-banner-text">
                We are believers of change! A change driven by technology and innovation.
                We help businesses and individuals in adapting as well as adopting digital transformation.
                Our aim is to improve businesses with our progressive and innovative technology solutions.
              </p>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div>
                  <p className="p-center-content-lm">
                    We are a digital transformation consultancy and engineering company that delivers solutions for global
                    organizations and technology startups. Since 2021 We have been helping companies and established brands
                    re-imagine their business through digitalization.
                    Nearly every organization will need to become a tech company in order to complete tomorrow.
                  </p>
                  <p className="p-center-content-lm">
                    At <b>SharepointEmpower </b>, we are on a mission to help companies develop competitiveness and agility
                    using the software.
                    We are a company with a mission to solve complex software engineering problems. We are dedicated to addressing intricate business automation challenges, having successfully partnered with a diverse range of clients, from startups that have gone public to Fortune 500 companies and forward-thinking enterprises.

                    Our Product Innovation Center transforms your engineering from being bottlenecks to growth drivers. We
                    help you to identify and solve critical business challenges with proven technology practices such as
                    DevOps, cloud-native development, and quality engineering services. Our remote agile teams of engineers
                    immerse themselves in your project, maintaining your company culture and working in line with your
                    strategic goals.
                  </p>
                </div>
              </div>
            </div>
            <div className="row about-us-inner-card-container">
              {
                OUR_VALUE_DATA?.map((ele, index) => {
                  return (
                    <div className="col col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3" key={index}>
                      <div className="about-us-inner-card-body flex justify-content-center align-items-center">
                        <span className="about-us-inner-card-title">

                          {ele.cardTitle === 'pi pi-globe' ?
                            <i className={ele.cardTitle} aria-hidden='true'></i>
                            :
                            ele.cardTitle
                          }
                        </span>
                        <span className="about-us-inner-card-subtitle">{ele.cardSubtitle}</span>
                        <p className="about-us-inner-card-content">
                          {ele.cardContent}
                        </p>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <div className="row">
              <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div>
                  <p className="p-center-content-lm">
                    We pride ourselves on creating a culture and environment that fosters creativity, collaboration, and
                    innovation. We believe that by providing our team members with the tools, resources, and support they
                    need to succeed, we can deliver the best possible results for our clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhoWeAre;