import React, { useEffect } from 'react'
import { BENEFITS_DATA } from '../../../utils/data';
import JobOpenings from '../jobOpenings/JobOpenings';
import CompanyCarousel from '../../slider/CompanyCarousel';
import ReactHelmetTag from '../../commonComponents/reactHelmet/ReactHelmetTag';
import Megamenu from '../../megamenu/Megamenu';
import './CareerAtSharepointEmpower.css';

const CareerAtSharepointEmpower = () => {

    useEffect(() => {
        document.title = "Career at SharepointEmpower";
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Megamenu />
            <ReactHelmetTag
                link="https://www.sharepointempower.com/career-at-sharepointempower"
                metaKeywords="career at sharepointempower, career at work"
            />
            <div className='mt-7'>
                <JobOpenings />
                <div className="container-fluid">
                    <div className="container">
                        <div className="row mt-4">
                            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <div>
                                    <h4 className="content-subtitle text-align-center">
                                        Perks & benefits at SharepointEmpower
                                    </h4>
                                </div>
                            </div>
                        </div>

                        <div className="row service-icon-container-lm">
                            {
                                BENEFITS_DATA?.map((ele, index) => {
                                    return (
                                        <div className="col col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" key={index}>
                                            <div className="service-icon-box-lm flex justify-content-center align-items-center">
                                                <ul className='flex flex-column align-items-center'>
                                                    <li className="text-align-center product-management-icon">
                                                        <img src={require(`../../../assets/images/icons/careerIcons/${ele.iconName}.png`)}
                                                            alt={ele.iconName} title=""/>
                                                    </li>
                                                    <li className="flex justify-content-center align-items-center"><span className="icon-card-span text-align-center">{ele.title}</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <div>
                                    <h5 className="content-subtitle text-align-center h5-title-anim">
                                        Company Culture and employee Engagement
                                    </h5>
                                </div>
                                <div className="light-mode-content">
                                    <p className="p-center-content-lm">
                                        We frequently encourage conducting training sessions, employee development meetings, and knowledge sharing within the company. The company's infrastructure provides modern equipment and the latest software to work with, which assist the team members work more efficiently. The employees are given enough space so that they can express themselves; put their innovative ideas into auction, thus creating a path to personal as well as company growth.
                                    </p>
                                    <p className="p-center-content-lm">
                                        We believe that engaging employees is of utmost importance for any organization, not to increase the job satisfaction but to build a good connection with the organization. Also, it is crucial that each and every employee works happily in an organization. One of the fundamentals of at SharepointEmpower Pvt. Ltd. is to make their employees' experience worth during their association. We frequently execute activities to add a little joy in busy schedule of our employees.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CompanyCarousel />
            </div>
        </>
    );
};

export default CareerAtSharepointEmpower;