import React, { useEffect, useState, useContext } from 'react';
import { MegaMenu } from 'primereact/megamenu';
import { Ripple } from 'primereact/ripple';
import { Link, useLocation } from 'react-router-dom';
import { ApiDataContext } from '../../context/ApiDataContext';
import useWindowSize from '../commonComponents/commonFunction/UseWindowSize';
import './Megamenu.css';

export default function Megamenu() {
    const { productPageData, theme, toggleTheme } = useContext(ApiDataContext);
    const [items, setItems] = useState([]);
    const [scrolled, setScrolled] = useState(false);
    const [logo, setLogo] = useState(require("../../assets/images/sharepointempowerLogo/spe-navbar-white.webp"));
    const { windowWidth } = useWindowSize();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/') {
            setLogo(require("../../assets/images/sharepointempowerLogo/spe-navbar-white.webp"));
        }
    }, [location.pathname]);

    useEffect(() => {
        if (productPageData) {
            headerMenu();
        }
    }, [productPageData, theme, windowWidth]);

    useEffect(() => {
        if (theme === "dark-mode-body") {
            setLogo(require("../../assets/images/sharepointempowerLogo/spe-navbar-black.webp"));
        } else {
            if (location.pathname !== '/' && theme === "dark-mode-body") {
                setLogo(require("../../assets/images/sharepointempowerLogo/spe-navbar-black.webp"));
            }
            else {
                setLogo(require("../../assets/images/sharepointempowerLogo/spe-navbar-white.webp"));
            }
        }
    }, [theme, location.pathname]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            setScrolled(scrollY > 200);

            if (location.pathname === '/') {
                if (theme === "dark-mode-body" && scrollY > 200) {
                    setLogo(require("../../assets/images/sharepointempowerLogo/spe-navbar-black.webp"));
                } else {
                    setLogo(require("../../assets/images/sharepointempowerLogo/spe-navbar-white.webp"));
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [theme, location.pathname]);

    const headerMenu = () => {
        const isBigScreen = windowWidth >= 768;
        if (productPageData && productPageData.length > 0) {

            const dynamicItemsLeft = productPageData.slice(0, 3)?.map(product => ({
                label: product.projectTitle,
                image: getLogoForProduct(product.projectTitle),
                subtext: isBigScreen ? product.menuProductDescription : '',
                path: product.routPath,
                template: itemRenderer
            }));
            const dynamicItemsCenter = productPageData.slice(3, 6)?.map(product => ({
                label: product.projectTitle,
                image: getLogoForProduct(product.projectTitle),
                subtext: isBigScreen ? product.menuProductDescription : '',
                path: product.routPath,
                template: itemRenderer
            }));
            const dynamicItemsRight = productPageData.slice(6, 9)?.map(product => ({
                label: product.projectTitle,
                image: getLogoForProduct(product.projectTitle),
                subtext: isBigScreen ? product.menuProductDescription : '',
                path: product.routPath,
                template: itemRenderer
            }));

            const updatedItems = [
                {
                    label: 'Products',
                    root: true,
                    template: itemRenderer,
                    path: '/all-products',
                    className: 'product-dropdown',
                    items: [
                        [
                            {
                                items: dynamicItemsLeft,

                            }
                        ],
                        [
                            {
                                items: dynamicItemsCenter,

                            }
                        ],
                        [
                            {
                                items: dynamicItemsRight,

                            }
                        ]
                    ]

                },
                {
                    label: 'Pricing',
                    root: true,
                    template: itemRenderer,
                    path: '/product-pricing-list',
                },
                {
                    label: 'Company',
                    className: 'company',
                    root: true,
                    template: itemRenderer,
                    items: [
                        [
                            {
                                items: [
                                    {
                                        label: 'About Us',
                                        icon: 'pi pi-info-circle icon-size',
                                        path: '/about-us',
                                        template: itemRenderer
                                    },
                                    {
                                        label: 'Find Us',
                                        icon: 'pi pi-map-marker icon-size',
                                        path: '/find-us',
                                        template: itemRenderer
                                    }
                                ]
                            }
                        ],
                        [
                            {
                                items: [
                                    {
                                        label: 'Career at SharepointEmpower',
                                        icon: 'pi pi-user icon-size',
                                        path: '/career-at-sharepointempower',
                                        template: itemRenderer
                                    },
                                    {
                                        label: 'Buy Now',
                                        icon: 'pi pi-shopping-cart icon-size',
                                        path: '/order-page',
                                        template: itemRenderer
                                    }
                                ]
                            }
                        ]
                    ]
                },
                {
                    label: 'Contact Us',
                    root: true,
                    template: itemRenderer,
                    path: '/contact-us',
                },
                {
                    label: 'FAQs',
                    root: true,
                    template: itemRenderer,
                    path: '/faqs',
                },
                {
                    template: () => (
                        <li className="nav-item">
                            <div
                                className="nav-link nav-link-toggler"
                                id="toggle-theme"
                                onClick={toggleTheme}
                            >
                                {theme === 'dark-mode-body' ?
                                    <i className="pi pi-moon toggle-image" aria-hidden="true"></i> :
                                    <i className="pi pi-sun toggle-image" aria-hidden="true"></i>}
                            </div>
                        </li>
                    )
                }

            ];

            setItems(updatedItems);
        }
    }

    function getLogoForProduct(productName) {
        switch (productName) {
            case 'Asset Management System':
                return theme === 'light' ? require('../../assets/images/icons/productIcons/ams-w.png') : require('../../assets/images/icons/productIcons/ams-b.png');
            case 'Learning Management Portal':
                return theme === 'light' ? require('../../assets/images/icons/productIcons/lmp-w.png') : require('../../assets/images/icons/productIcons/lmp-b.png');
            case 'Audit Inspection':
                return theme === 'light' ? require('../../assets/images/icons/productIcons/audit-w.png') : require('../../assets/images/icons/productIcons/audit-b.png');
            case 'Construction Management System':
                return theme === 'light' ? require('../../assets/images/icons/productIcons/cms-w.png') : require('../../assets/images/icons/productIcons/cms-b.png');
            case 'SPE Intranet':
                return theme === 'light' ? require('../../assets/images/icons/productIcons/spe-Intranet-w.png') : require('../../assets/images/icons/productIcons/spe-Intranet-b.png');
            case 'HelpDesk System':
                return theme === 'light' ? require('../../assets/images/icons/productIcons/hd-w.png') : require('../../assets/images/icons/productIcons/hd-b.png');
            case 'Inventory Management System':
                return theme === 'light' ? require('../../assets/images/icons/productIcons/ims-w.png') : require('../../assets/images/icons/productIcons/ims-b.png');
            case 'Approval System':
                return theme === 'light' ? require('../../assets/images/icons/productIcons/as-w.png') : require('../../assets/images/icons/productIcons/as-b.png');
            default:
                return null;
        }
    }

    const itemRenderer = (item) => {
        let updatedTitle = item.title;
        if (item.root) {
            return (
                <Link to={item.path} className="flex align-items-center cursor-pointer overflow-hidden relative p-ripple">
                    <span className={item.icon} />
                    <span className="header-font-bold get-in-touch-border">{item.label}</span>
                    <Ripple />
                </Link>
            );
        } else if (!item.image) {
            return (
                <Link to={item.path} className="flex align-items-center cursor-pointer gap-2">
                    {item.icon && (
                        <span className="inline-flex align-items-center justify-content-center border-circle h-2rem">
                            <i className={item.icon}></i>
                        </span>
                    )}
                    <span className="inline-flex flex-column company-menu-underline">
                        <span>{item.label}</span>
                        <span>{item.subtext}</span>
                    </span>
                </Link>
            );
        }

        return (
            <Link to={item.path} className="flex flex-column gap-2">
                <div className="flex gap-1 flex-item-img subtext">
                    <img alt="megamenu-demo" className='w-2rem product-logo' src={item.image} title="" />
                    <div className='mobile-text'>
                        <span className='product-font'>{item.label}</span>
                        {theme === "dark-mode-body" ?
                            <img className="microsoft-badge ml-1" src={require('../../../src/assets/images/microsoftSolutionsPartner/microsoft-badge-black.png')} alt={updatedTitle} title="" /> :
                            <img className="microsoft-badge ml-1" src={require('../../../src/assets/images/microsoftSolutionsPartner/microsoft-badge-white.png')} alt={updatedTitle} title="" />}
                        {item.subtext && <p className='text-sm font-normal line-height-3 subtext'>{item.subtext}</p>}
                    </div>
                </div>
            </Link>
        );

    };

    const start = (
        <Link to="/">
            <img
                src={logo}
                fetchpriority="high"
                className="logo d-inline-block align-text-top"
                alt="navbar.png"
                title=""
            />
        </Link>
    );

    return (
        <div className={`megamenu-container ${location.pathname === '/' ? (scrolled ? 'blurred' : 'transparent') : 'scrolled'}`}>
            <MegaMenu start={start} model={items} orientation="horizontal" breakpoint="960px" />
        </div>
    );
};
