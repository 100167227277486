import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ApiDataContext } from "../../../context/ApiDataContext";
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import { Galleria } from 'primereact/galleria';
import { Button } from 'primereact/button';
import { DeferredContent } from 'primereact/deferredcontent';
import { ScrollTop } from 'primereact/scrolltop';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import useWindowSize from "../commonFunction/UseWindowSize";
import Introduction from "../../../common/helpCenterIcons/Introduction";
import GettingStarted from "../../../common/helpCenterIcons/GettingStarted";
import InstallationGuide from "../../../common/helpCenterIcons/InstallationGuide";
import AdminGuide from "../../../common/helpCenterIcons/AdminGuide";
import AssetManagerGuide from "../../../common/helpCenterIcons/AssetManagerGuide";
import UserGuide from "../../../common/helpCenterIcons/UserGuide";
import ActivateLicense from "../../../common/helpCenterIcons/ActivateLicense";
import EnableExternalSharing from "../../../common/helpCenterIcons/EnableExternalSharing";
import UpgradeAppGuide from "../../../common/helpCenterIcons/UpgradeAppGuide";
import MaterialSymbolsDelete from "../../../common/helpCenterIcons/MaterialSymbolsDelete";
import SharepointSitePermission from "../../../common/helpCenterIcons/SharepointSitePermission";
import { ProgressSpinner } from 'primereact/progressspinner';
import Megamenu from "../../megamenu/Megamenu";
import Footer from "../../footer/Footer";
import './Allprojects.css';

export default function AllProjects({ project, path }) {
  const [level2Categories, setLevel2Categories] = useState([]);
  const [level1Category, setLevel1category] = useState([]);
  const [level3Categories, setLevel3Categories] = useState([]);
  const [description, setDescription] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedLevel2, setSelectedLevel2] = useState(null);
  const [selectedLevel3Category, setSelectedLevel3Category] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [imgUrls, setImgUrls] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const [openItem, setOpenItem] = useState(null);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const inputRef = useRef(null);
  const descriptionContainerRef = useRef(null);
  const galleria = useRef(null);

  const { helpCenterData } = useContext(ApiDataContext);
  const { isLoading } = useContext(ApiDataContext);
  const { level1category, level2categoryitem, level3categoryitem } = useParams();
  const navigatation = useNavigate();
  const { windowWidth } = useWindowSize();

  const handleOpenSubMenuAndCategoryChange = async (category) => {
    await handleCategoryChange({ value: category });

    setSelectedLevel2(null);
    setSelectedLevel3Category(null);

    if (openSubMenu === category) {
      setOpenSubMenu(null);
    } else {
      setOpenSubMenu(category);
    }
  };

  // extract image URL from Description
  useEffect(() => {
    const extractImgUrls = () => {
      const tempImgUrls = [];
      const regex = /<img[^>]+src="([^">]+)"/g;
      let match;
      while ((match = regex.exec(description)) !== null) {
        const itemImageSrc = match[1].replace(/&#58;/g, ":");
        const thumbnailImageSrc = itemImageSrc.replace('/Images/', '/Thumbnails/');
        tempImgUrls?.push({ itemImageSrc, thumbnailImageSrc });
      }
      setImgUrls(tempImgUrls);
    };

    extractImgUrls();
  }, [description]);

  const handleImageClick = async (event) => {
    if (event.target.tagName === 'IMG') {
      const index = imgUrls.findIndex(img => img.itemImageSrc === event.target.src);
      await setSelectedIndex(index);
      galleria.current.show();
    }
  };

  // image click crousal
  const itemTemplate = (item) => {
    return (
      <img
        src={item?.itemImageSrc}
        alt={item?.alt}
        style={{ width: '75%', height: '100%', display: 'block' }}
        title=""
      />
    );
  };

  const onItemChange = (event) => {
    setSelectedIndex(event.index)
  }

  const renderFooter = () => {
    return (
      <div className="flex justify-content-center text-white">
        {
          imgUrls && imgUrls?.length > 0 && (
            <span className="p-3">
              <span className="p-2">{selectedIndex + 1}/{imgUrls?.length}</span>
              <span className="p-2">{selectedLevel2} {selectedLevel3Category && ` / ${selectedLevel3Category}`}</span>
            </span>
          )
        }
      </div>
    );
  }
  const footer = renderFooter();

  useEffect(() => {

    // Load level2 and level3 data
    level2();
    level3();
    reloddisplay();

    // Load description for selected level2 category
    if (level2categoryitem) {
      const selectedData = helpCenterData?.find(
        (item) =>
          (item.projectName === project || item.isCommonData == true) &&
          item.level1Category.toLowerCase().replace(/\s/g, '-') === level1category &&
          item.level2Category.toLowerCase().replace(/\s/g, '-') === level2categoryitem
      );
      setDescription(selectedData ? selectedData?.description : "");
    }

    // Load description for selected level3 category
    if (level3categoryitem) {
      const selectedData = helpCenterData?.find(
        (item) =>
          item.projectName === project &&
          item.level1Category?.toLowerCase().replace(/\s/g, '-') === level1category &&
          item.level3Category?.toLowerCase().replace(/\s/g, '-') === level3categoryitem
      );
      setDescription(selectedData ? selectedData.description : "");

      setTimeout(() => {
        setSelectedLevel3Category(selectedData?.level3Category);
      }, 100);
    }
  }, [helpCenterData, project, level1category, level2categoryitem, level3categoryitem]);

  const handleCategoryChange = (e) => {
    const newCategory = e.value;
    setLevel1category(newCategory);
    navigatation(`/guidelines/${project.toLowerCase().replace(/\s/g, '-')}/${newCategory.toLowerCase().replace(/\s/g, '-')}`);
  };

  const convertTitleCase = (str) => {
    return str.replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });
  }

  const reloddisplay = (defaultCategory = "") => {

    let selectedData = helpCenterData?.find(
      (item) =>
        (item.projectName === project || item.isCommonData == true) &&
        item.level1Category.toLowerCase().replace(/\s/g, '-') === (level1category || defaultCategory) &&
        (item.sequence === 1 || item.sequence === 1.001 ||
          item.sequence === 1.01 ||
          item.sequence === 2.01 ||
          item.sequence === 3.01 ||
          item.sequence === 4.01 || item.sequence === 5.01 || item.sequence === 6.01 || item.sequence === 7.01 || item.sequence === 8.01 || item.sequence === 9.01 || item.sequence === 10.01 || item.sequence === 11.01 || item.sequence === 12.01)
    );

    if (selectedData) {
      // setSelectedLevel2(selectedData.level2Category);
      setDescription(selectedData.description);
    } else {
      setDescription("");
    }
  };

  const data = helpCenterData?.filter((item) => (item.projectName === project || item.isCommonData == true));
  const projectNameTitle = helpCenterData?.filter((item) => (item.projectName === project));
  let updateprojectname = projectNameTitle[0]?.projectName;

  if (projectNameTitle[0]?.projectName?.includes('Management') && projectNameTitle[0]?.projectName?.includes('System')) {
    updateprojectname = projectNameTitle[0]?.projectName.replace('System', '').trim();
  }

  let level1data = helpCenterData
    ?.filter((e) => (e.projectName === project || e.isCommonData == true) && e.productGuideIsActive)
    ?.map((e) => ({ category: e.level1Category, sequence: e.sequence, level2Data: e.level2Category, level3CategoryData: e.level3Category }));

  level1data = level1data.sort((a, b) => a.sequence - b.sequence);

  const level1Categories = level1data?.map((item) => ({
    category: item.category,
    level2Data: item.level2Data,
    level3CategoryData: item.level3CategoryData,
  }));

  //sidebar's icon
  const categoryIconMap = {
    "Introduction": <Introduction />,
    "Getting Started": <GettingStarted />,
    "Installation Guide": <InstallationGuide />,
    "Admin Guide": <AdminGuide />,
    "Asset Manager Guide": <AssetManagerGuide />,
    "User Guide": <UserGuide />,
    "Activate License": <ActivateLicense />,
    "Enable External Sharing": <EnableExternalSharing />,
    "Upgrade App Guide": <UpgradeAppGuide />,
    "Uninstallation Guide": <MaterialSymbolsDelete />,
    "Sharepoint site Permission": <SharepointSitePermission />
  };

  const level1CategoriesWithIcons = level1Categories?.map((category) => ({
    label: category.category,
    icon: categoryIconMap[category.category],
    level2Data: category.level2Data,
    level3CategoryData: category.level3CategoryData
  }));

  let level2 = () => {
    const filteredLevel2Categories = helpCenterData
      ?.filter(
        (item) =>
          (item.projectName === project || item.isCommonData == true) && item.level1Category.toLowerCase().replace(/\s/g, '-') === level1category && item.productGuideIsActive
      )
      ?.map((item) => ({
        category: item.level2Category,
        level3category: item.level3Category,
        sequence: item.sequence,
        discription: item.description,
      }));

    level2 = filteredLevel2Categories.sort((a, b) => a.sequence - b.sequence);

    const categoryData = [...new Set(level2?.map((x) => x.category))];

    const level2Categoriesdata = [
      ...new Set(
        categoryData?.map((e) => ({
          level2data: e,
          level3data: level2?.filter((x) => x.category == e)[0].level3category,
        }))
      ),
    ];
    setLevel2Categories(level2Categoriesdata);
  };

  let level3 = () => {
    const filteredLevel3Categories = helpCenterData
      ?.filter(
        (item) => (item.projectName === project && item.level3Category !== null) && item.productGuideIsActive
      )
      ?.map((item) => ({
        level1Name: item.level1Category,
        level2Name: item.level2Category,
        sequence: item.sequence,
        category: item.level3Category,
        discription: item.description,
      }));

    level3 = filteredLevel3Categories.sort((a, b) => a.sequence - b.sequence);
    setLevel3Categories(level3);
  };

  // next-Previous level2category 
  useEffect(() => {
    if (level2Categories.length > 0) {
      setCurrentIndex(level2Categories.findIndex((item) => item.level2data === selectedLevel2));
    }
  }, [level2Categories, selectedLevel2]);

  const handleNextCategory = () => {
    if (currentIndex < level2Categories.length - 1) {
      const nextCategory = level2Categories[currentIndex + 1];
      setCurrentIndex(currentIndex + 1);
      handleLevel2CategoryClick(nextCategory);
    }
  };

  const handlePreviousCategory = () => {
    if (currentIndex > 0) {
      const prevCategory = level2Categories[currentIndex - 1];
      setCurrentIndex(currentIndex - 1);
      handleLevel2CategoryClick(prevCategory);
    }
  };

  const handleLevel2CategoryClick = (category) => {

    setSelectedLevel2(category.level2data);
    setSelectedLevel3Category(null);

    const selectedData = data?.find(
      (item) =>
        (item.projectName === project || item.isCommonData == true) &&
        item.level1Category?.toLowerCase().replace(/\s/g, '-') === level1category &&
        item.level2Category?.toLowerCase().replace(/\s/g, '-') === category.level2data?.toLowerCase().replace(/\s/g, '-')
    );

    if (selectedData) {
      setTimeout(() => {
        setDescription(selectedData.description);
      }, 100);
    } else {
      setDescription("");
    }
    navigatation(`/guidelines/${project?.toLowerCase().replace(/\s/g, '-')}/${level1category?.toLowerCase().replace(/\s/g, '-')}/${category.level2data?.toLowerCase().replace(/\s/g, '-')}`);
  };

  const handleToggle = (level2data) => {
    setOpenItem(openItem === level2data ? null : level2data);
  };

  const handleLevel3DropdownChange = (item) => {
    setSelectedLevel3Category(item.category);
    setSelectedLevel2(item.level2Name);

    const selectedData = helpCenterData?.find(
      (data) =>
        data.projectName === project &&
        data.level1Category?.toLowerCase().replace(/\s/g, '-') === level1category &&
        data.level3Category?.toLowerCase().replace(/\s/g, '-') === item.category.toLowerCase().replace(/\s/g, '-')
    );

    if (selectedData) {
      setDescription(selectedData.description);
    } else {
      setDescription("");
    }

    navigatation(`/guidelines/${project.toLowerCase().replace(/\s/g, '-')}/${item.level1Name.toLowerCase().replace(/\s/g, '-')}/${item.level2Name.toLowerCase().replace(/\s/g, '-')}/${item.category.toLowerCase().replace(/\s/g, '-')}`);
  };

  // search category
  const similarWords = ['Plan', 'Pricing'];
  const faqsWords = ['FAQ'];

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    clearTimeout(timeoutId);
    const newTimeoutId = setTimeout(() => {
      if (query.trim() === '') {
        setSuggestions([]);
        return;
      }

      const filteredLevel1 = data
        ?.filter((category) =>
          category.level1Category.toLowerCase().includes(query.toLowerCase())
        )
        ?.map((category) => category.level1Category);

      const filteredLevel2 = data
        ?.filter((category) =>
          category?.level2Category?.toLowerCase()?.includes(query.toLowerCase())
        )
        ?.map((category) => category?.level2Category);

      const filteredLevel3 = data
        ?.filter((category) =>
          category?.level3Category?.toLowerCase()?.includes(query.toLowerCase())
        )
        ?.map((category) => category?.level3Category);

      const filteredSimilarWords = [...similarWords, ...faqsWords]?.filter(word =>
        word.toLowerCase().includes(query.toLowerCase())
      );

      const allFilteredCategories = [
        ...new Set([
          ...filteredLevel1,
          ...filteredLevel2,
          ...filteredLevel3,
          ...filteredSimilarWords,
        ]),
      ];

      setSuggestions(allFilteredCategories);
    }, 200);
    setTimeoutId(newTimeoutId);
  };

  const clearSearch = () => {
    setSearchQuery('');
  };

  // searchbox suggetion
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        setSelectedSuggestionIndex(prevIndex => Math.max(prevIndex - 1, 0));
      } else if (event.key === 'ArrowDown') {
        event.preventDefault();
        setSelectedSuggestionIndex(prevIndex => Math.min(prevIndex + 1, suggestions.length - 1));
      } else if (event.key === 'Enter') {
        event.preventDefault();
        if (selectedSuggestionIndex !== -1) {
          handleSuggestionSelect(suggestions[selectedSuggestionIndex]);
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedSuggestionIndex, suggestions.length]);

  useEffect(() => {
    if (inputRef.current) {
      const selectedSuggestion = inputRef.current.querySelector('.selected');
      if (selectedSuggestion) {
        selectedSuggestion.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  }, [selectedSuggestionIndex]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setSuggestions([]);
        setSelectedSuggestionIndex(-1);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSuggestionSelect = (selectedCategory) => {

    if (similarWords.includes(selectedCategory)) {
      navigatation("/product-pricing-list");
    } else if (faqsWords.includes(selectedCategory)) {
      navigatation("/faqs");
    } else {
      const matchedLevel2 = data?.find(category =>
        category.level2Category === selectedCategory
      );

      const matchedLevel3 = level3Categories.find(category =>
        category.category.toLowerCase() === selectedCategory.toLowerCase()
      );

      if (matchedLevel2) {
        navigatation(`/guidelines/${project.toLowerCase().replace(/\s/g, '-')}/${matchedLevel2.level1Category.toLowerCase().replace(/\s/g, '-')}/${matchedLevel2.level2Category.toLowerCase().replace(/\s/g, '-')}`);
      } else if (matchedLevel3) {
        navigatation(`/guidelines/${project.toLowerCase().replace(/\s/g, '-')}/${matchedLevel3.level1Name.toLowerCase().replace(/\s/g, '-')}/${matchedLevel3.level2Name.toLowerCase().replace(/\s/g, '-')}/${matchedLevel3.category.toLowerCase().replace(/\s/g, '-')}`);
      } else {
        handleCategoryChange({ value: selectedCategory });
      }
    }
    setSuggestions([]);
    setSearchQuery('');
  };

  // copy selected category
  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    setShowCopyMessage(true);
    setTimeout(() => {
      setShowCopyMessage(false);
    }, 2000);
  }

  // breadcrumb of helpcenter
  useEffect(() => {
    setSelectedLevel2(null);
    setSelectedLevel3Category(null);
  }, [level1category]);

  const items = [
    { label: updateprojectname, template: () => <Link to={path}>{updateprojectname}</Link> },
    level1category && {
      label: (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => handleOpenSubMenuAndCategoryChange(level1category)}
        >
          {level1category
            .replace(/-/g, ' ')
            .replace(/\b\w/g, (char) => char.toUpperCase())}
        </span>
      ),
    },
    selectedLevel2 && { label: selectedLevel2 },
    selectedLevel3Category && { label: selectedLevel3Category }
  ].filter(Boolean);

  const home = { icon: 'pi pi-home', template: <Link to="/"><i className="pi pi-home"></i></Link> };

  const sidebarToggle = () => {
    setCollapsed(!collapsed);
    clearSearch();
  }

  return (
    <>
      <Megamenu />
      {!isLoading ?
        <div className="container-fluid">
          <div className={`product-guide-container pb-0 mt-7`}>
            {windowWidth < 951 && (
              <button className="toggle-button border-none" onClick={() => setCollapsed(!collapsed)}>
                <i className="pi pi-bars"></i>
              </button>
            )}
            <div className="row">
              <div className={!collapsed ? "col col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2 col-xxl-2 pt-0" : "col col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1 col-xxl-1 pt-0"}>
                <div className="help-center-mobile-sidebar">
                  {helpCenterData.length > 0 ? (
                    <>
                      <div className={windowWidth < 992 ? "flex justify-content-between align-items-center" : ''}>
                        <div style={{ display: 'flex', height: 'calc(100vh - 75px)' }}>

                          <Sidebar
                            collapsed={collapsed}
                            className="sidebar-component"
                          >
                            <div className="flex justify-content-around m-1">
                              {windowWidth > 992 && (
                                <div>
                                  <button className="toggle-button" onClick={sidebarToggle}>
                                    <i className="pi pi-bars"></i>
                                  </button>
                                </div>
                              )}
                              {!collapsed && (
                                <div>
                                  {helpCenterData.length > 0 && (
                                    <div ref={inputRef} className="relative w-full">
                                      <span className="p-input-icon-right w-full">
                                        <i
                                          className={`pi ${searchQuery ? 'pi-times' : 'pi-search'}`}
                                          onClick={searchQuery ? clearSearch : null}
                                          style={{ cursor: searchQuery ? 'pointer' : 'default' }}
                                        />
                                        <InputText
                                          placeholder="Search"
                                          value={searchQuery}
                                          onChange={handleSearchInputChange}
                                          className="p-inputtext-sm w-auto p-1"
                                        />
                                      </span>
                                      {suggestions.length > 0 && (
                                        <ul className="suggestions absolute left-0 w-full">
                                          {suggestions?.map((category, index) => (
                                            <li
                                              key={index}
                                              onClick={() => handleSuggestionSelect(category)}
                                              className={`${index === selectedSuggestionIndex ? 'selected' : ''}`}
                                            >
                                              {category}
                                            </li>
                                          ))}
                                        </ul>
                                      )}
                                      {searchQuery !== '' && suggestions.length === 0 && (
                                        <div className="suggestions absolute left-0 w-full max-h-48 overflow-y-auto border border-gray-300 bg-white rounded shadow flex items-center justify-center">
                                          No results found.
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                            <Menu closeOnClick>
                              {/* Render Level 1 Categories */}
                              {Object.entries(
                                level1CategoriesWithIcons.reduce((acc, cat) => {
                                  acc[cat.label] = acc[cat.label] || [];
                                  acc[cat.label].push(cat);
                                  return acc;
                                }, {})
                              )?.map(([label, items]) => {
                                const categoryLabel = convertTitleCase(label.replace(/\-/g, ' '));
                                const category = items[0];

                                if (items.length === 1) {
                                  return (
                                    <MenuItem
                                      key={label}
                                      icon={category.icon}
                                      title={categoryLabel}
                                      active={true}
                                      onClick={() => handleOpenSubMenuAndCategoryChange(label)}
                                      className={`${level1Category === category.label ? 'selected-item-active' : ''}`}
                                    >
                                      <div className='d-flex justify-content-between align-items-center'>
                                        {categoryLabel}
                                        <span>
                                          {level1Category === category.label ? (
                                            <i className="prime-icon pi pi-link cursor-pointer text-xs" onClick={copyToClipboard}></i>
                                          ) : (
                                            <i></i>
                                          )}
                                        </span>
                                      </div>
                                    </MenuItem>
                                  );
                                }

                                return (
                                  <SubMenu
                                    label={categoryLabel}
                                    key={label}
                                    icon={category.icon}
                                    title={categoryLabel}
                                    onClick={() => handleOpenSubMenuAndCategoryChange(label)}
                                    open={openSubMenu === category.label}
                                    className='custom-submenu'
                                  >
                                    {/* Level 2 Categories */}
                                    {level1Category === label && level2Categories.length > 1 && (
                                      <>
                                        {level2Categories?.map((item) =>
                                          item.level3data ? (
                                            <SubMenu
                                              key={item.level2data}
                                              label={item.level2data}
                                              title={item.level2data}
                                              onClick={() => handleToggle(item.level2data)}
                                            >
                                              {/* Level 3 Categories */}
                                              {level3Categories
                                                ?.filter((e) => e.level2Name === item.level2data)
                                                ?.map((subItem) => (
                                                  <MenuItem
                                                    key={subItem.category.label}
                                                    title={subItem.category.label}
                                                    onClick={() => handleLevel3DropdownChange(subItem)}
                                                    active={true}
                                                    className={`${selectedLevel3Category === subItem.category ? 'selected-item-active' : ''} pl-3`}
                                                  >
                                                    <div
                                                      className={`d-flex justify-content-between align-items-center ${selectedLevel3Category === subItem.category ? 'selected-item-active' : ''}`}>
                                                      {subItem.category.length > 20
                                                        ? `${subItem.category.substring(0, 20)}...`
                                                        : subItem.category}
                                                      <span>
                                                        {selectedLevel3Category === subItem.category ? (
                                                          <i className="prime-icon pi pi-link cursor-pointer text-xs" onClick={copyToClipboard}></i>
                                                        ) : (
                                                          <i></i>
                                                        )}
                                                      </span>
                                                    </div>
                                                  </MenuItem>
                                                ))}
                                            </SubMenu>
                                          ) : (
                                            <MenuItem
                                              key={item.level2data}
                                              onClick={() => {
                                                handleLevel2CategoryClick(item);
                                              }}
                                              title={item.level2data}
                                              active={true}
                                              className={`${selectedLevel2 === item.level2data ? 'selected-item-active' : ''}`}
                                            >
                                              <div
                                                className={`d-flex justify-content-between align-items-center text-wrap ${selectedLevel2 === item.level2data ? 'selected-item-active' : ''
                                                  }`}
                                              >
                                                {item.level2data.length > 30
                                                  ? `${item.level2data.substring(0, 30)}...`
                                                  : item.level2data}

                                                <span>
                                                  {selectedLevel2 === item.level2data ? (
                                                    <i className="prime-icon pi pi-link cursor-pointer text-xs" onClick={copyToClipboard}></i>
                                                  ) : (
                                                    <i></i>
                                                  )}
                                                </span>
                                              </div>
                                            </MenuItem>
                                          )
                                        )}
                                      </>
                                    )}
                                  </SubMenu>
                                );
                              })}
                            </Menu>

                          </Sidebar>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Skeleton width="100%" height="40px" className="mt-2" />
                      <Skeleton width="100%" height="250px" className="mt-3" />
                    </>
                  )}
                </div>
              </div>
              <div className={!collapsed ? "col col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 p-0" : "col col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10 p-0"}>
                <div className="pl-5 pr-3">
                  <div>
                    {helpCenterData.length > 0 ? (
                      <div className="product-guide-inner-container" ref={descriptionContainerRef}>
                        <div id="description-container" className={windowWidth < 992 ? "light-mode-title" : 'light-mode-title pr-2'}>
                          <DeferredContent>
                            <div className=" breadcrumb-section flex justify-content-between sticky top-0">
                              <div>
                                <BreadCrumb model={items} home={home} />
                              </div>
                              <div className="next-button-container">
                                <div className="next-prev-button">
                                  <Button onClick={handlePreviousCategory} disabled={currentIndex === 0}><i className="pi pi-chevron-left"></i></Button>
                                </div>
                                <div className="next-prev-button">
                                  <Button onClick={handleNextCategory} disabled={currentIndex === level2Categories.length - 1}><i className="pi pi-chevron-right"></i></Button>
                                </div>
                              </div>
                            </div>
                            <div className="light-mode-title help-para" onClick={handleImageClick} dangerouslySetInnerHTML={{ __html: description }}></div>
                          </DeferredContent>
                          <Galleria
                            ref={galleria}
                            value={imgUrls}
                            onItemChange={onItemChange}
                            activeIndex={selectedIndex}
                            numVisible={imgUrls?.length}
                            style={{ maxWidth: '100VW', maxHeight: '100%' }}
                            circular
                            fullScreen
                            showItemNavigators
                            showThumbnails={false}
                            item={itemTemplate}
                            footer={footer}
                          />
                        </div>
                        <ScrollTop target="parent" threshold={100} className="w-3rem h-3rem description-top-scroll ml-auto " icon="pi pi-angle-up text-base" />
                      </div>
                    ) : (
                      <Skeleton width="95%" height="600px" />
                    )}
                  </div>
                </div>
              </div>
              <div className="col col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1 col-xxl-1">
              </div>
            </div >
          </div >
        </div >
        :
        <div className="card flex justify-content-center h-screen loading-spinner">
          <ProgressSpinner />
        </div>
      }
      <Footer />
      {showCopyMessage && (<div className="copy-message">Link copied to clipboard successfully.</div>)}
    </>
  );
}